import styled from "styled-components";
import React from "react";

const Container = styled.div`
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
`;

const Title = styled.h1`
	text-align: center;
`;

const Subtitle = styled.h2``;

const Description = styled.div``;

function MissingPage() {
	return (
		<Container>
			<Title>404</Title>
			<Subtitle>We can't find that page</Subtitle>
			<Description>
				We're fairly sure that page used to be here, but seems to have
				gone missing. We do apologise on it's behalf.
			</Description>
		</Container>
	);
}

export default MissingPage;
